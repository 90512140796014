.agent_list{
    position: relative;
    display: block;
}

.agent_list__list{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: $margin-medium;
    grid-row-gap: $margin-medium;

    @media screen and (max-width: $tablet-breakpoint){
        grid-template-columns: auto auto;
    }

    @media screen and (max-width: $mobile-breakpoint){
        grid-template-columns: auto;
    }
}

.timed_agent_label{
    font-size: 0.8rem;
    margin-top: -0.1rem;
    font-weight: 500;
    span{
        font-style: italic;
        color: #777;
    }

    .exceeded{
        color: $warning-red;
    }
}

.agent_list__item{
    display: flex;
    align-items: center;
    padding: $padding-large $padding-medium;
    border: 1px solid $border-color;
    border-radius: $border-radius-small;
    .agent_profile_avatar{
        margin-bottom: 0px;
    }

    .status_display{
        font-size: 0.8rem;
        color: #777;
        margin-bottom: $margin-small;
    }

    .badge{
        padding: 0.2rem 0.5rem;
        font-size: 0.7rem
    }

    &.select_list_item{
        cursor: pointer;
        transition: background 0.3s;

        &:hover{
            background: $offwhite;
        }
    }

    &.selected{
        border: 3px solid $primary-color;
        background: white !important;
    }
}

.agent_list__item__details{
    margin-left: $margin-small;
    flex-grow: 1;
}

.agent_list_item__name{
    font-weight: 500;
    margin-bottom: 0.2rem;
}

.agent_list__item__details__actions{
    display: flex;
    margin-left: $margin-medium;
}


.agent_list__item__details__action{
    font-size: 1.1rem;
    color: $grey;
    cursor: pointer;    
    margin-right: 1rem;
    &:last-child{
        margin-right: 0px;
    }
}

.agent_list_topbar{
    display: flex;
    justify-content: space-between;

    input{
        max-width: 300px;
    }
}

.agent_filters{
    display: flex;
    flex-shrink: 0;
    > div{
        width: 50vw;
        max-width: 150px;
    }
}