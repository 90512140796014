.search_container{
    flex-grow: 1;
    display: flex;
    max-width: 300px;
    flex-direction: column;
}

.help_request_search{
    display: flex;
    width: 100%;
    height: fit-content;
    .btn{
        margin-left: $margin-small;
        border-radius: 0.25rem;
        svg{
            margin: 0px;
        }
    }

    input{
        margin-bottom: 0px;
        width: 100%;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        width: 100%;
        margin-bottom: $margin-medium;
        max-width: unset;
        input{
            flex-grow: 1;
            width: unset;
        }
    }
}

.search{
    width: 100%;
}