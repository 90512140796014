.chat_message_bar{
    .quick_replies{
        padding: $padding-medium;
        border-top: 1px solid $border-color-light;
    }
}

.chat_send_button{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    flex-shrink: 0;
    width: 35px;
    font-size: 1rem;
    height: 35px;
    margin-left: 0.5rem;
    background-color: $primary-color;
    color: white;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover{
        background-color: darken($primary-color, 4%);
    }

    &[disabled]{
        cursor: not-allowed;
        opacity: 0.5;
        
        &:hover{
            background-color: $primary-color;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        margin-left: 0px;
    }
}

.chat_message_bar__message_bar{
    position: relative;
    align-items: center;
    background: white;
    border-top: 1px solid $border-color-light;
    padding: $padding-medium;

    @media screen and (max-width: $mobile-breakpoint) {
        padding-bottom: $padding-medium;
    }

    .button{
        width: 100px;
        margin-left: 0.5rem;
    }

    .chat_message_bar__message_bar__input{
        position: relative;
        display: flex;
        align-items: center;
    }

    .chat_message_bar__message_bar__input_area{
        position: relative;
        flex-grow: 1;
        display: flex;
        align-items: center;

        .add_to_quick_reply__container{
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            right: 0;
            height: 100%;
            width: 40px;
        }

        .add_to_quick_reply{
            position: relative;
            height: fit-content;
            color: $primary-color;
            cursor: pointer;
            font-size: 1.4rem;
            line-height: 1.4rem;

            svg{
                display: flex;
                align-items: center;
            }
        }

        .add_to_quick_reply_loader{
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 100%;
            font-size: 1.2rem;
            color: $primary-color;
            top: 0;
            right: 0;
            cursor: default;
        }

        &.internal{
            textarea{
                background-color: lighten($secondary-color, 42%);
                border-color: lighten($secondary-color, 35%);;
            }
        }
    }

    textarea{
        border-radius: 1.5rem;
        outline: none;
        padding: 0.4rem 1rem;
        border: 1px solid rgba(0,0,0,.1);
        max-height: 50vh;
        padding-right: 40px;
        transition: background-color 0.2s ease;

        &::-webkit-scrollbar {
            width: .3em;
            padding-top: 1rem;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 1rem;
            cursor: default;
        }
        &::-webkit-scrollbar-track {
            background: #eceff1;
            margin: 1rem 0px;
        }
    }
}

.chat_message_bar__actions{
    display: flex;
    background-color: white;
    margin-top: 0.7rem;
    align-items: center;

    @media screen and (max-width: $mobile-breakpoint) {
        padding-bottom: 45px;
    }
}

.message_bar_alert{
    @extend .meeting_alert_message;
    display: flex;
    align-items: center;
    svg{
        font-size: 2rem;
        line-height: 2rem;
        margin-right: $margin-small;
        color: $primary-color;
    }

    &::before{
        position: absolute;
        height: 5px;
        width: 100%;
        left: 0;
        bottom: 0px;
        background-color: rgba(darken(white, 35%), 0.5);
        content: "";
        animation: borderClock 5s forwards;
    }
}

@keyframes borderClock{
    from{
        width: 100%;
    }
    to{
        width: 0%;
    }
}

.chat_message_bar__action{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    cursor: pointer;
    color: rgba($charcoal, 0.75);
    transition: 0.2s;
    margin: 0px 0.4rem;
    background-color: transparent;
    border: none;
    padding: 0px;

    @media screen and (max-width: $mobile-breakpoint) {
        font-size: 1.4rem;
        margin: 0px 0.8rem;
    }

    &:disabled{
        opacity: 0.4;
        cursor: default;
    }

    svg{
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s;
    }

    &:hover{
        svg, .chat_message_bar__action__label{
            color: $primary-color;
        }
    }
}