* {
    box-sizing: border-box;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 

}

.no_background{
    background: transparent !important;
}

body{    
    position: fixed!important;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;
    background-color: $offwhite;
}

.mobile_hide{
    @media screen and (max-width: $mobile-breakpoint) {
        display: none !important;
    }
}

#root, .App{
    position: fixed!important;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;
}

a{
    color: $grey;
    transition: all 0.3s;

    &:hover{
        color: $primary-color;
    }
}

.label_title{
    font-weight: 500;
    margin-bottom: $margin-small;
    color: $charcoal;
}

.search_container{
    @media screen and (max-width: $mobile-breakpoint) {
        width: 100%;
    }
}

.mobile_filter_trigger{
    display: none !important;
    margin-bottom: $margin-medium;

    @media screen and (max-width: $mobile-breakpoint) {
        display: flex !important;
    }
}

.agent_name_element{
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    line-height: 1rem;
    flex-grow: 1;

    span{
        flex-grow: 1;
    }

    .badge{
        padding: 0.2rem 0.5rem;
        font-size: 0.65rem;
        height: fit-content !important;
        width: fit-content !important;

        &:first-child{
            margin-left: 0.2rem;
        }
    }
}

.toggle_custom{
    .react-toggle-track-x, .react-toggle-track-check{
        top: 50%;
        margin-bottom: unset;
        margin-top: -7px;
    }
    svg{
        color: white;
        margin: 0px;
    }
}

p{
    font-size: 0.8rem;
}

svg.success{
    color: $online-color;
}

.center{
    text-align: center;
}

.flex-middle{
    display: flex;
    align-items: center;
}

.flex-center{
    display: flex;
    justify-content: center;
}

.flex-grow{
    display: flex;
    flex-grow: 1;
}

.page_title{
    font-size: 2rem;
    font-weight: 300;
    margin-bottom: $margin-large;
}

.page_description{
    color: #888;
    font-size: 0.9rem;
    margin-bottom: $margin-large;
}

.text-truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.count_badge{
    position: absolute;
    display: flex;
    bottom: -15%;
    left: -25%;
    background-color: $warning-red;
    color: white;
    height: 1rem;
    width: 1rem;
    line-height: 1rem;
    border-radius: 50%;
    font-size: 0.5rem;
    font-weight: bold;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.timestamp{
    color: #888;
    font-size: 0.7rem;
}

.info{
    font-style: italic;
    color: #888;
    font-size: 0.8rem;
    display: block;
}

.notification_badge{
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    background-color: $primary-color;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 0.55rem;
    font-weight: bold;
    margin-right: 1rem;
}

.attachment{
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    border-radius: $border-radius-medium;
    cursor: pointer;
    transition: all 0.1s;
    font-weight: 500;
    width: 100%;
    justify-content: center;
    text-align: center;
    background-color: rgba($primary-color, 0.04);
    padding: 0.75rem $padding-medium;

    &:hover{
        color: $primary-color;
    }

    svg{
        margin-right: $margin-small;
        font-size: 1.2rem;
        border-radius: $border-radius-medium;
        color: $primary-color;
    }
}

.italics{
    font-style: italic;
    color: #777;
}

.container{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;

    &.column{
        flex-direction: column;
    }

    @media(max-width: $mobile-breakpoint){
        flex-direction: column;
    }
}

.col-3{
    width: 33%;
    flex-shrink: 0;
    @media(max-width: $mobile-breakpoint){
        width: 100%;
    }
}

.col-6{
    width: 50%;
    flex-shrink: 0;
    @media(max-width: $mobile-breakpoint){
        width: 100%;
    }
}

.col-8{
    width: 66%;
    flex-shrink: 0;
    @media(max-width: $mobile-breakpoint){
        width: 100%;
    }
}

.col-12{
    width: 100%;
    flex-shrink: 0;
}

.my-medium{
    margin-top: $margin-medium;
    margin-bottom: $margin-medium;
}

.mb-medium{
    margin-bottom: $margin-medium;
}

.mt-medium{
    margin-top: $margin-medium;
}

.mt-xl{
    margin-top: 3rem;
}

.ml-medium{
    margin-left: $margin-medium;
}

.mr-medium{
    margin-right: $margin-medium;
}

.my-medium{
    margin-top: $margin-medium;
    margin-bottom: $margin-medium;
}

.mb-medium{
    margin-bottom: $margin-medium;
}

.mt-medium{
    margin-top: $margin-medium;
}

.ml-medium{
    margin-left: $margin-medium;
}

.mx-medium{
    margin-left: $margin-medium;
    margin-right: $margin-medium;
}

.m-medium{
    margin: $margin-medium;
}

.py-medium{
    padding-top: $padding-medium;
    padding-bottom: $padding-medium;
}

.pb-medium{
    padding-bottom: $padding-medium;
}

.pt-medium{
    padding-top: $padding-medium;
}

.pl-medium{
    padding-left: $padding-medium;
}

.pr-medium{
    padding-right: $padding-medium;
}

.py-medium{
    padding-top: $padding-medium;
    padding-bottom: $padding-medium;
}

.pb-medium{
    padding-bottom: $padding-medium;
}

.pt-medium{
    padding-top: $padding-medium;
}

.pl-medium{
    padding-left: $padding-medium;
}

.px-medium{
    padding-left: $padding-medium !important;
    padding-right: $padding-medium !important;
}

.p-medium{
    padding: $padding-medium;
}

::-webkit-scrollbar {
    width: .3em;
}
::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,.1);
    border-radius: 1rem;
}
::-webkit-scrollbar-track {
    background: #eceff1;
}

.react-toggle--checked .react-toggle-track{
    background-color: $primary-color !important;
}

.react-toggle--checked .react-toggle-thumb{
    border-color: $primary-color !important;
}

.react-toggle--focus .react-toggle-thumb{
    box-shadow: none !important;
}

.table{
    width: 100%;
    border-spacing: 0px;
    border-radius: $border-radius-small;
    overflow: hidden;

    thead{
        box-shadow: 0px 0px 10px rgba(black, 0.08);
    }
    th{
        text-align: left;
        padding: 0.8rem $padding-medium;
        font-weight: 700;
        font-size: 0.8rem;
    }

    tbody{
        max-height: 200px;
        td{
            padding: $padding-medium;
            font-weight: 400;
            border-bottom: 1px solid $border-color;
            font-size: 0.8rem;
        }
    }

    .loadMoreColumn{
        border-bottom: none;
        padding-bottom: 0px;
    }
}