.chats_filter{
    padding: $padding-medium;

    .filter{
    }

    > div:last-child { 
        .filter{
            margin-bottom: 0px;
        }
    }
}

.date_range_filter{
    .DateRangePickerInput {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .DateInput {
        width: unset;
    }
}

.d-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}