.internal_chat_room{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
}

.internal_chat_room_top_bar{
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $border-color-light;
    padding: $padding-medium 0;
    padding-right: $padding-medium;
}

.internal_chat_room_content{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
}

.internal_chat_room_top_bar__back{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #777;
    width: 60px;
    font-size: 1.5rem;
    cursor: pointer;
    flex-shrink: 0;

    svg{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.internal_chat_room_top_bar__chatting_to{
    display: flex;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
    margin-right: $margin-medium;

    .agent_profile_avatar{
        margin-bottom: 0px;
        margin-left: 0px;
    }

    .status_display{
        font-size: 0.8rem;
        svg{
            margin-right: 0.1rem;
        }
    }
}

.internal_chat_room_top_bar__chatting_to_details{
    flex-grow: 1;
}

.internal_chat_room_top_bar__chatting_to_details__name{
    font-weight: 500;
}

.internal_chat_room__message_container{
    flex-grow: 1;
    display: flex;
    background-color: $offwhite;
    flex-direction: column;
    overflow: auto;

    .message_media_item__fit img, .message_media_item__fit video{
        max-width: 250px;
        max-height: 250px;
    }

    .message__buble{
        max-width: 250px;
    }

}

.quick_message_text_area{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;

    .quick_replies{
        width: 100%;
        margin-bottom: $margin-medium;

        .quick_reply_list{
            height: 200px;
        }
    }
}

.internal_chat_room__input_area{
    position: relative;
    display: flex;
    align-items: center;
    padding: $padding-medium;
    border-top: 1px solid $border-color-light;

    .chat_message_bar__message_bar__media{
        @extend .emoji_picker__triger;
        cursor: pointer;
        margin-left: 0px;

    }

    textarea{
        border-radius: 5rem !important;
    }

    &.disabled{
        justify-content: center;
        font-size: 0.8rem;
        font-weight: 500;
        color: $grey;
    }

    .send_button{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: none !important;
        outline: none !important;
        flex-shrink: 0;
        width: 35px;
        height: 35px;
        margin-left: 0.5rem;
        background-color: $primary-color;
        color: white;
        cursor: pointer;
        transition: all 0.1s ease-in-out;

        &:hover{
            background-color: darken($primary-color, 4%);
        }

        &[disabled]{
            cursor: not-allowed;
            opacity: 0.5;
            
            &:hover{
                background-color: $primary-color;
            }
        }
    }
}

.internal_chat_list_item_details__last_activity{
    font-size: 0.8rem;
    color: #777;

    .text-span{
        svg{
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.internal_create_group_chat_list{
    max-height: 300px;
    overflow-y: auto;

    .internal_agent_list_item{
        padding-left: 0px;
        padding-right: 0px;
        cursor: pointer;

        &:hover{
            background-color: white;

            .internal_agent_list_item__details__name, .status_display span{
                color: $primary-color;
            }
        }
    }
}