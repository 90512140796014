.chats_notes_list_item{
    position: relative;
    display: block;
    margin-bottom: $margin-large;
}

.chats_notes_list_item__agent{
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-weight: 500;
    color: #777;
    margin-bottom: $margin-small;

    .agent_profile_avatar{
        margin: 0px;
        margin-right: $margin-small;
    }

    .timestamp{
        flex-shrink: 0;
        font-size: 0.7rem;
        line-height: 0.7rem;
    }
}

.chats_notes_list_item__agent__name{
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.chats_notes_list_item__text{
    margin-bottom: $margin-medium;
    padding: $padding-medium;
    font-size: 0.8rem;
    white-space: pre-line;
    border: 1px solid $border-color-light;
    border-radius: $border-radius-medium;
    box-shadow: 0px 5px 7.5px rgba(black, 0.05);
}

.chat_notes_add{
    position: relative;
    margin-bottom: $margin-medium;
}

.max_length{
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    line-height: 0.8rem;
    color: #777;
    margin-right: $margin-small;
}

.delete_note{
    flex-shrink: 0;
    margin-left: 0.1rem;
    color: $charcoal;
    cursor: pointer;
}