.internal_chat{
    position: fixed;
    display: flex;
    flex-direction: column;
    bottom: 0;
    right: 5%;
    z-index: 50;

    @media screen and (max-width: $mobile-breakpoint) {
        right: 2%;
    }

    aside.EmojiPickerReact{
        right: -20px;
        display: none;
    }
}

.internal_chat_trigger{
    position: relative;
    display: flex;
    align-self: flex-end;
    width: fit-content;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 0.5rem $padding-medium;
    box-shadow: 3.5px -3.5px 15px rgba(black, 0.1);
    border-top-right-radius: $border-radius-medium;
    border-top-left-radius: $border-radius-medium;
    border: 1px solid $border-color-light;
    border-bottom: 0px;
    cursor: pointer;
    font-size: 1rem;
    color: $charcoal;
    font-size: 0.8rem;
    font-weight: 600;
    svg{
        margin-right: $margin-small;
        color: $primary-color;
        font-size: 1.2rem;
    }

    .notification_badge{
        margin-right: 0px;
        margin-left: $margin-small;
    }
}

.internal_chat_widget__menu{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .flex_grow_container{
        overflow: hidden;
    }
}

.flex_grow_container{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.internal_chat_widget{
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 400px;
    max-height: 600px;
    height: 90vh;
    border: 1px solid $border-color;
    box-shadow: 0px 0px 15px rgba(black, 0.1);
    border-top-right-radius: $border-radius-small;
    border-top-left-radius: $border-radius-small;
    animation: fadeIn 0.3s;
    overflow: hidden;

    @media screen and (max-width: $mobile-breakpoint) {
        position: fixed;
        max-width: unset;
        max-height: unset;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}

.internal_chat_widget__title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 1.5rem;
    padding: $padding-medium;
    border-bottom: 1px solid $border-color;

    svg{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #888;
    }
}

.internal_chat_widget__close_chat{
    cursor: pointer;
    font-size: 1.5rem;
    margin-right: 1rem;
    flex-shrink: 0;

    &:last-child{
        margin-right: 0px;
    }
    svg{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #888;
    }
}

.internal_chat_top_bar{
    position: relative;
    display: flex;
    padding: $padding-medium;
    align-items: center;
    justify-content: space-between;
    svg{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.internal_chat_search{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

    input{
        border: none;
        margin-bottom: 0px;
    }

    .search_action_button{
        background-color: white;
        border: none;
        font-size: 1.3rem;
        cursor: pointer;
        outline: none;
    }
}

.internal_chat_top_bar__actions{
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: 1rem;
}

.internal_chat_top_bar__action{
    font-size: 1.3rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
}

.internal_chat_widget__content{
    position: relative;
    display: flex;
    overflow: auto;
    padding: 0px;
    width: 100%;
    flex-grow: 1;
}

.internal_chats_list__message_badge{
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    color: #777;
    cursor: default;

    .btn{
        margin-top: 1rem;
        background-color: white;
        svg{
            font-size: 1.2rem;
            margin-right: 0.3rem;
        }
    }
}

.internal_chats_list__message_badge__icon{
    font-size: 2.5rem;
}

.internal_chats_list{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

$chat_avatar_width: 50px;
$chat_avatar_height: 50px;

.internal_chat_list_item{
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: center;
    padding: $padding-medium;
    border-bottom: 1px solid $border-color-light;
    cursor: pointer;
    transition: background-color 0.1s ease-in-out;
    width: 100%;
    flex-shrink: 0;

    &:hover{
        background-color: $offwhite;
    }

    &:last-child{
        border-bottom: 0px;
    }

    .agent_profile_avatar{
        margin-bottom: 0px;
    }
}

.group_chat_icon{
    @extend .agent_profile_avatar;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $user-color;
    font-size: 1.5rem;
    color: white;
    border-radius: 50%;
    margin: 0 8px;
}

.internal_chat_list_item_details{
    position: relative;
    display: block;
}

.internal_chat_list_item_details__chatting_to{
    position: relative;
    font-weight: 500;
}

.internal_notifications__item{
    position: relative;
    display: flex;
    align-items: center;
    background-color: white;
    box-shadow: 0px 0px 15px rgba(black, 0.1);
    margin-bottom: $margin-medium;
    width: 300px;
    padding: $padding-large $padding-small;
    border-radius: $border-radius-medium;
    padding-right: $padding-medium;
    cursor: pointer;
    animation: fadeIn 0.3s ease-in-out;
    overflow: hidden;

    .agent_profile_avatar{
        margin-bottom: 0px;
    }

    .timestamp{
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 0.3rem;
        font-size: 0.65rem;
    }
}

.internal_chat_list_item_details{
    position: relative;
    flex-grow: 1;
    display: block;
    overflow: hidden;
}

.internal_notifications__item__name{
    font-weight: 500;
}

.internal_notifications__item__message_preview{
    color: #777;
    max-width: 280px;
    .text-span{
        svg{
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.internal_notifications__item__dismiss{
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.3rem;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s ease-in-out;

    &:hover{
        background-color: $offwhite;
        border-bottom-left-radius: $border-radius-medium;
    }
}